import React, { Component } from 'react';
import Shareicon from './share-icon';
import Relatedpost from './related-posts';
import HeaderBottom from '../components-global/Header-bottom';
import homedata from '../../data/homedata.json';

class PaygoTip extends Component {

  render() {
   let publicUrl = process.env.PUBLIC_URL
   let headerPageTitle = "How to become a PAYGO product Distributor - Tips"

    return (
       <div>
      <HeaderBottom headertitle={headerPageTitle} />
      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">

                  <div className="single-blog-content page-content">
                     <div className="blog-post-img">
                        <img src="/assets/img/blog/tips-paygo.jpg" alt='paygo'></img>
                     </div>
                     <div className="blog-content">
                        <h1 className="m-20px-b">How to become a PAYGO product distributor - tips</h1>
                        <div className="post-meta m-15px-b">
                           <ul>
                              <li><a href={homedata.blogs.url}><i className="icofont-user-alt-5"></i> Admin</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-tag"></i> FAQ</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-calendar"></i> 01 Feb 23</a></li>
                           </ul>
                        </div>
                        <div className="single-blog-post">
                        <h2>Anyone anywhere</h2>
                        <p>Our platform has been designed to enable anyone anywhere to use quick, low cost PAYGO payments and services based on the OpenPAYGO system.</p>

                        <p>Before you take the plunge, there are several aspects of being a PAYGO product distributor that you need to research and plan for</p>

                        <h2>Research the local market</h2>
                        <ul>
                        <li>Is there a demand? Can you spot an area that can be filled?</li>
                        <li>What prices are other shops/distributors selling similar products for?</li>
                        <li>What is the average monthly price people can afford?</li>
                        <li>Can you make a profit after costs?</li>
                        <li>Can you secure the funding for your first order?</li></ul>
                        <p>These are basic questions for research and will provide you with a greater understanding of your local market</p>
                        <p></p>
                           <h2>Make a business plan</h2>
                           <p>Once you have done your research, its time to make a business plan. The business plan will help structure your ideas into actions and would also help towards securing funding</p>
                           <ul>
                        <li>Executive summary, include market opportunity, competitive advantages, and financial highlights as a start</li>
                        <li>Business description and structure</li>
                        <li>Marketing plan and strategy</li>
                        <li>Financial plan</li></ul>
                           <p></p>

                                 <h2>Get Funding </h2>
                                 <p>There are now more ways to secure funding than ever before, just be aware of the interest rates and the fine print should you fail to make a loan payment</p>
                           <ul>
                        <li>Start with family and friends</li>
                        <li>Check with local banks</li>
                        <li>Research online development loans from organisations </li>
                        <li>Are there grants available?</li></ul>
                        <p>Securing funds may be the hardest part. You could start with a smaller order and reinvest the profits into another larger order and so on.</p>
                        <p>PAYGOCrypto is aiming to provide a loan service in the future, or partner with an existing loan provider. Our aim will be to make it easier to secure funding for distributors using the PAYGOCrypto platform, stay tuned and subscribe to our email list </p>


                        </div>
                     </div>
                  </div>

                  {
                  // Social Share icon 
                  <Shareicon />
                  }  
                  {
                  // Related Post
                  <Relatedpost />
                  }  
             

               </div>
            </div>
         </div>
      </section>
      </div>
    );
  }
}



export default PaygoTip;
