import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
class Contact extends Component {
  render() {
    return (

      <section id="contact" className="p-80px-tb">
         <div className="container">
            <div className="row">
               <div className="col-sm-8 offset-sm-2">
                  <div className="section-title text-center m-50px-b">
                     <h2>{homedata.contact.title}</h2>
                  </div>
               </div>
            </div>
            <div className="row row-eq-height">
               <div className="col-md-12 col-md-6 contact-address p-30px">
               <div className="address-box text-center p-15px">
                     <i className={homedata.contact.contactinfo.email.icon}></i>
                     <h5>{homedata.contact.contactinfo.email.title}</h5>
                     <p dangerouslySetInnerHTML={{__html: homedata.contact.contactinfo.email.info}}></p>
                  </div>
                  <div className="address-box text-center p-15px m-15px-b">
                     <i className={homedata.contact.contactinfo.address.icon}></i>
                     <h5>{homedata.contact.contactinfo.address.title}</h5>
                     <p dangerouslySetInnerHTML={{__html: homedata.contact.contactinfo.address.info}}></p>
                  </div>

               </div>

            </div>
         </div>
      </section>

    );
  }
}

export default Contact;
