import React, { Component } from 'react';
import Shareicon from './share-icon';
import Relatedpost from './related-posts';
import HeaderBottom from '../components-global/Header-bottom';
import homedata from '../../data/homedata.json';

class PaygoHow extends Component {

  render() {
   let imgattr = 'image'
   let publicUrl = process.env.PUBLIC_URL
   let headerPageTitle = "PAYGO Crypto How it Works"

    return (
       <div>
      <HeaderBottom headertitle={headerPageTitle} />

   {/* <Helmet>
        <title>{`${homedata.blogs ? "title" : "Client Title"}`}</title>
        <meta name="description" content={`${homedata.blogs ? "Admin Content" : "Client Content"}`} />
    </Helmet> */}

      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">

                  <div className="single-blog-content page-content">
                     <div className="blog-post-img">
                        <img src="/assets/img/blog/how-paygo-works.jpg" alt='paygo'></img>
                     </div>
                     <div className="blog-content">
                        <h1 className="m-20px-b">How it works</h1>
                        <div className="post-meta m-15px-b">
                           <ul>
                              <li><a href={homedata.blogs.url}><i className="icofont-user-alt-5"></i> Admin</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-tag"></i> FAQ</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-calendar"></i> 01 Feb 23</a></li>
                           </ul>
                        </div>
                        <div className="single-blog-post">
                        <p>Our platform has been designed to enable anyone anywhere to use quick, low cost PAYGO payments and services based on the OpenPAYGO system.</p>
                        <p>Transactions occur on the Stellar network using the stablecoin USDC.</p>
                        <h2>From the User or Customer perspective</h2>
                        <p>A user who has a product or device from a distributor using our services need only to send the agreed amount in USDC to the payment address and include the distributor code and device serial in the payment memo. This is in the example format (distributorcode-deviceserial) 735-90002761</p>
                        <p>Our system then does several checks:</p>
                        <ul>
                        <li>Checks memo for distributor code</li>
                        <li>Checks memo for serial number</li>
                        <li>Checks payment amount agreed by distributor</li>
                        <li>Checks the total amount paid against the contact cost</li></ul>
                        <p>If the checks pass, our system will send the user a new PAYG code based on the amount to days</p>
                        <p>Our system then deducts the processing fee based on the distributors plan and sends the remaining amount to the distributors wallet.</p>
                        <p>If the checks fail, our system sends the funds back to the originator</p>
                           <p></p>
                           <div className="row m-15px-t">
                              <div className="col-md-12">
                                 <img src={ publicUrl + '/assets/img/payment-flow-paygo.png' } alt={imgattr}/>
                                 <p></p>
                              </div>
                              <br></br>
                              <h2>From a Distributor's perspective</h2>
                              <div className="col-md-12">
                              <p>A distributor registers on our platform and completes the profile information required for approval.
                              </p>
                        <p>The distributor then:</p>
                        <ul>
                        <li>Uploads device information as per the instructions in the admin panel</li>
                        <li>Sets the prices for customer rental packages</li>
                        <li>Begins selling the devices to customers</li>
                        <li>Adds a user profile to each device distributed</li>
                        <li>Receives payments when a customer makes a payment</li>

                        </ul>
                        <p>Our system deducts the processing fee based on the distributors plan and sends the remaining amount to the distributors wallet.</p>

                           <p>Payments are handled by the platform, when a user pays their rental, the distributor receives the payment minus our percentage fee in USDC direct to their wallet.</p> <br></br>
                           <p>Transactions are recorded on the blockchain and are immutable, they cannot be modified and are publicly available. No personal identifying information is recorded on the blockchain, only device and payment information. </p>
                           <p>USDC can then be cashed out via the digital wallet at local MoneyGram locations or other USDC to Local Fiat exchanges.</p>
                           <div className="col-md-12">
                                 <img src={ publicUrl + '/assets/img/sign-up-distributor.png' } alt={imgattr}/>
                                 <p></p>
                              </div>
                                 <p>Read more about the Stellar network and USDC stablecoin</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  {
                  // Social Share icon 
                  <Shareicon />
                  }  
                  {
                  // Related Post
                  <Relatedpost />
                  }  
             

               </div>
            </div>
         </div>
      </section>
      </div>
    );
  }
}

export default PaygoHow;
