import React, { Component } from 'react';
import Shareicon from './share-icon';
import Relatedpost from './related-posts';
import HeaderBottom from '../components-global/Header-bottom';
import homedata from '../../data/homedata.json';

class PageNotFound extends Component {

  render() {
   let headerPageTitle = "404 Page not found"

    return (
       <div>
      <HeaderBottom headertitle={headerPageTitle} />
      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">

                  <div className="single-blog-content page-content">
                     <div className="blog-post-img">
                        <img src="/assets/img/404-error.jpg" alt='Error 404'></img>
                     </div>
                     <div className="blog-content">
                        <h1 className="m-20px-b">Beepboop, Please try the menu to find what you are looking for</h1>
                     </div>
                  </div>

                  {
                  // Social Share icon 
                  <Shareicon />
                  }  
                  {
                  // Related Post
                  <Relatedpost />
                  }  
             

               </div>
            </div>
         </div>
      </section>
      </div>
    );
  }
}

export default PageNotFound;
