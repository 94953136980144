import React, { Component } from 'react';
import Shareicon from './share-icon';
import Relatedpost from './related-posts';
import HeaderBottom from '../components-global/Header-bottom';
import homedata from '../../data/homedata.json';

class BlockPay extends Component {

  render() {
   let publicUrl = process.env.PUBLIC_URL
   let headerPageTitle = "Blockchain PAYGO Payments"

    return (
       <div>
      <HeaderBottom headertitle={headerPageTitle} />
      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">

                  <div className="single-blog-content page-content">
                     <div className="blog-post-img">
                        <img src="/assets/img/blog/blockchain-concept.jpg" alt='blockchain concept'></img>
                     </div>
                     <div className="blog-content">
                        <h1 className="m-20px-b">Blockchain PAYGO Payments</h1>
                        <div className="post-meta m-15px-b">
                           <ul>
                              <li><a href={homedata.blogs.url}><i className="icofont-user-alt-5"></i> Admin</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-tag"></i> FAQ</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-calendar"></i> 01 Feb 23</a></li>
                           </ul>
                        </div>
                        <div className="single-blog-post">
                        <p>Our platform has been designed to enable anyone anywhere to use quick, low cost PAYGO payments and services based on the OpenPAYGO system.</p>
                        <h2>Blockchain</h2>
                        <p>We are moving towards a more decentralised digital future and blockchain or Distributed Ledger Technology (DLT) will be a big part of the change.  </p>
                        <p>Although blockchain can be decentralised, centralised or a mixture of both, it is the decentralised flavour that will gain public approval in the Web3 environment.</p>
                        <h3>Benefits of Blockchain</h3>
                        <ul>
                        <li>Transactions are considered "immutable" in that they are unable to be changed once written</li>
                        <li>Enhanced security via encryption methods</li>
                        <li>Transparency, most public blockchains have transaction explorers</li>
                        <li>Traceability, blockchain provides an audit trail that cannot be changed </li>
                        <li>Low cost and fast transactions are a Stellar thing, others will follow </li>
                        <li>Global 24/7 access for public networks such as Stellar, Bitcoin, Ethereum etc </li>

                        </ul>
                        <p></p>
                           <h2>PAYGO</h2>
                           <p>PAYGO devices have made rapid progress in countries where access to traditional finance is scarce. Families with low and fluctuating income rely on PAYGO enabled products to provide the basics in life, power, clean water and cooking.</p>
                           <p>The number and range of products utilising PAYGO will continue to increase in countries where energy, water and financial infrastructures are immature or non existent.</p>
                           <h3>Benefits of PAYGO devices</h3>
                           <ul>
                        <li>Households can gain access to life changing products where traditional finance options are not available</li>
                        <li>The device cost can be spread over an agreed period of time</li>
                        <li>Lower costs for the household replacing fuel and phone charging costs</li>
                        <li>If the customer does not pay for the device usage, the device switches off until payment is made</li>
                        <li>The distributor has a greater guarantee that the loan will be repaid</li>
                        <li>Provides financial inclusion</li>

                        </ul>
                        <p>The benefits of using blockchain for PAYGO payments are numerous and far-reaching. It eliminates the need for intermediaries, reducing transaction costs and increasing efficiency, resulting in lower costs for both the provider and the customer.</p>
                        <p>In addition, blockchain technology offers unparalleled security and privacy. All transactions are recorded on the ledger, which is distributed across a network of nodes, making it virtually impossible to hack or tamper with the data. This offers peace of mind to customers and providers alike, knowing that their transactions are safe and secure.</p>
                        <p>Another key benefit of using blockchain for PAYGO payments is the potential for financial inclusion. By using simple, low-cost technology such as mobile phones, it becomes possible to reach even remote, rural communities and provide access to financial services to those who need it the most.</p>
                        <p>In conclusion, using blockchain for PAYGO payments offers a secure, efficient, and cost-effective solution that can drive financial inclusion and benefit both providers and customers.</p>
                        <br></br><p>At PAYGOCrypto, we believe the combination of blockchain and PAYGO payment services are the future.</p>
                        <p>Imagine, anyone anywhere can pay a customer's rental, could be a last mile payment by a distributor, a friend or relative, or a donation from the other side of the world...</p>
                           <div className="row m-15px-t">
                              <div className="col-lg-6 col-md-12">
                                 <img src={ publicUrl + '/assets/img/jua-energy.jpg' } alt="Jua Energy"/>
                                 <a href="https://www.juaenergy.com/"  target="_blank" rel="noreferrer">PAYGO products by Jua Energy</a>
                                 <p>Jua Energy provide a wide range of high quality Small Home Solar products that utilise LiFePo4 battery cells that have a 3-5 times lifespan over typical Lithium batteries supplied in competitor products. They also supply solar panel's that are more than capable of charging the product they are packaged with. Some manufacturer's offer high capacity low quality batteries with inadequate solar panels which eventually costs the consumer more money over time.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  {
                  // Social Share icon 
                  <Shareicon />
                  }  
                  {
                  // Related Post
                  <Relatedpost />
                  }  
             

               </div>
            </div>
         </div>
      </section>
      </div>
    );
  }
}

export default BlockPay;
