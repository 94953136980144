import React, { Component } from 'react';
import Shareicon from './share-icon';
import Relatedpost from './related-posts';
import HeaderBottom from '../components-global/Header-bottom';
import homedata from '../../data/homedata.json';

class SetupWallet extends Component {

  render() {
   let publicUrl = process.env.PUBLIC_URL
   let headerPageTitle = "Setup a digital wallet"

    return (
       <div>
      <HeaderBottom headertitle={headerPageTitle} />
      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">

                  <div className="single-blog-content page-content">
                     <div className="blog-post-img">
                        <img src="/assets/img/blog/digital-wallet-paygo.jpg" alt='paygo'></img>
                     </div>
                     <div className="blog-content">
                        <h1 className="m-20px-b">Setup a digital wallet for the Stellar network</h1>
                        <div className="post-meta m-15px-b">
                           <ul>
                              <li><a href={homedata.blogs.url}><i className="icofont-user-alt-5"></i> Admin</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-tag"></i> FAQ</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-calendar"></i> 01 Feb 23</a></li>
                           </ul>
                        </div>
                        <div className="single-blog-post">
                        <p>Want to know how to setup a wallet for Stellar transactions? Follow the steps below to get access to one of the best and most secure Stellar based wallets available today. Lobstr also pre-fund the wallet so you don’t need to activate it before use, you can also set a federation address for your profile</p>
                        <h2>Lobstr wallet</h2>
                        <p>Steps;</p>
                        <ol type="1">
                        <li>Visit https://lobstr.co and choose your wallet type, Desktop, Google Play or App Store</li>
                        <li>Click “Get Started” if you are using the desktop version</li>
                        <li>Enter your email and password to create an account</li>
                        <li>Open your email client and verify your email via the link sent from Lobstr</li>
                        <li>Once logged in to Lobstr, you will be given the opportunity to save the wallet address, secret key and seed phrase</li>
                        <li>Important! Make sure you save this information somewhere safe</li>
                        <li>You can now add the USDC trustline from your wallet dashboard (see notes below)</li>
                        <li>Note! The secret wallet key can be imported to other wallets and DEX, make sure any platform you use is secure</li>
                        </ol>
                        <p>NOTE 1: We will activate wallets with a small amount of XLM for approved distributors as part of the onboarding process.</p><br></br>
                        <p>NOTE 2: Non-distributor setup - If you cannot add the USDC trustline due to a low XLM balance, we recommend to add a minimum of 4 XLM which will cover the 0.5XLM per asset trustline and several thousand transaction fees</p>
                        <br></br>
                        <h2>Lobstr setup video</h2>
                        <p>Please watch the setup video</p>
                        <div className="row align-items-center">
                        <div className="col-lg-4 col-md-6"></div>
               <div className="col-lg-4 col-md-6">
                     <video className="preface-video" src={publicUrl+homedata.walletvid.vidurl} autoPlay="false" loop="loop" muted="muted" type="video/mp4"></video>
                     <div className="col-lg-4 col-md-6"></div>

                  </div>
                  </div>
                        </div>
                     </div>
                  </div>

                  {
                  // Social Share icon 
                  <Shareicon />
                  }  
                  {
                  // Related Post
                  <Relatedpost />
                  }  
             

               </div>
            </div>
         </div>
      </section>
      </div>
    );
  }
}

export default SetupWallet;
