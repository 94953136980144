import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as HLink } from 'react-router-hash-link';

class Header extends Component {

   render() {

      return (
      <div>
         <header id="home">
            <nav className="navbar navbar-inverse navbar-expand-lg header-nav fixed-top light-header">
               <div className="container">
                  <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                     <img className="navbar-logo" src={process.env.PUBLIC_URL + '/assets/img/logo-paygo-crypto.png' } alt="PAYGO Crypto Logo"/>
                  </a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCodeply">
                     <i className="icofont-navigation-menu"></i>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarCodeply">
                     <ul className="nav navbar-nav ml-auto">
                        <li><HLink className="nav-link" smooth to={`/#home`}>Home</HLink> </li>
                        <li><HLink className="nav-link" smooth to={`/#how-it-works`}>About</HLink></li>
                        <li><HLink className="nav-link" smooth to={`/#feature`}>Features</HLink></li>
                        <li><HLink className="nav-link" smooth to={`/#feature2`}>Wallet</HLink></li>
                        <li><HLink className="nav-link" smooth to={`/#screenshots`}>Screenshots</HLink></li>
                        <li><HLink className="nav-link" smooth to={`/#pricing`}>Pricing</HLink></li>
                        <li><HLink className="nav-link" smooth to={`/#contact`}>Contact</HLink></li>
                        <li>
                           <HLink className="nav-link" smooth to={`/faq`}>FAQ</HLink>
                           <span className="sub-menu-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="icofont-rounded-down"></i>
                           </span>
                           <ul className="sub-menu">
                              <li><NavLink to="/paygo-crypto-how-it-works">How it works</NavLink></li>
                              <li><NavLink to="/setup-a-digital-wallet">Digital wallet setup</NavLink></li>
                              <li><NavLink to="/tips-how-to-become-a-paygo-distributor">Distributor tips</NavLink></li>
                              <li><NavLink to="/stellar-usdc">Stellar and USDC</NavLink></li>
                              <li><NavLink to="/faq">FAQs Full</NavLink></li>
                           </ul>
                        </li>
                     </ul>
                  </div>
                  </div>
               </nav>
      </header>      
      </div>

    );
   }
}

export default Header;
