import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route} from "react-router-dom";
import blogdata from './data/blogdata.json';
import Singleblogdata from './data/single-blogdata.json';
import Footerdata from './data/footerdata.json';

import Home from './components/Home';
import Header from './components/components-global/Header';
import Footer from './components/components-global/Footer';
import Singleblog from './components/components-blog/single-blog';
import PaygoHow from './components/components-blog/paygo-crypto-how-it-works';
import SetupWallet from './components/components-blog/setup-a-digital-wallet';
import PaygoTip from './components/components-blog/tips-how-to-become-a-paygo-distributor';
import FAQ from './components/components-blog/faq';
import StellarUsdc from './components/components-blog/stellar-usdc';
import BlockPay from './components/components-blog/blockchain-paygo-payments';
import UsdcBlockchain from './components/components-blog/how-blockchain-and-usdc-can-improve-paygo';
import PageNotFound from './components/components-blog/PageNotFound';

class Root extends Component {
  render(){
      return (
          <BrowserRouter basename="/">
              <div>
                  <Header />
                  <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/single-blog" render={ () => { return <Singleblog data={Singleblogdata} /> } } />
                      <Route path="/faq" render={ () => { return <FAQ data={blogdata} /> } } />
                      <Route path="/paygo-crypto-how-it-works" render={ () => { return <PaygoHow data={blogdata} /> } } />
                      <Route path="/setup-a-digital-wallet" render={ () => { return <SetupWallet data={blogdata} /> } } />
                      <Route path="/tips-how-to-become-a-paygo-distributor" render={ () => { return <PaygoTip data={blogdata} /> } } />
                      <Route path="/stellar-usdc" render={ () => { return <StellarUsdc data={blogdata} /> } } />
                      <Route path="/blockchain-paygo-payments" render={ () => { return <BlockPay data={blogdata} /> } } />

                      <Route path="/how-blockchain-and-usdc-can-improve-paygo" render={ () => { return <UsdcBlockchain data={blogdata} /> } } />
                      <Route path="*" render={ () => { return <PageNotFound /> } } />
                  </Switch>
                  <Footer data={Footerdata} />
              </div>
          </BrowserRouter>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));