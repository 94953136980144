import React, { Component } from 'react';
import Shareicon from './share-icon';
import Relatedpost from './related-posts';
import HeaderBottom from '../components-global/Header-bottom';
import homedata from '../../data/homedata.json';

class SetupWallet extends Component {

  render() {
   let publicUrl = process.env.PUBLIC_URL
   let headerPageTitle = "Stellar Blockchain - USDC"

    return (
       <div>
      <HeaderBottom headertitle={headerPageTitle} />
      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">

                  <div className="single-blog-content page-content">
                     <div className="blog-post-img">
                        <img src="/assets/img/usdc-stellar-network.jpg" alt='paygo'></img>
                     </div>
                     <div className="blog-content">
                        <h1 className="m-20px-b">About the Stellar network and USDC</h1>
                        <div className="post-meta m-15px-b">
                           <ul>
                              <li><a href={homedata.blogs.url}><i className="icofont-user-alt-5"></i> Admin</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-tag"></i> FAQ</a></li>
                              <li><a href={homedata.blogs.url}><i className="icofont-calendar"></i> 01 Feb 23</a></li>
                           </ul>
                        </div>
                        <div className="single-blog-post">
                        <p>Our platform has been designed to enable anyone anywhere to use quick, low cost PAYGO payments and services based on the OpenPAYGO system.</p>
                        <h2>Stellar network</h2>
                        <p>The Stellar network is a global blockchain based payments system and was founded in 2014 by Jed McCaleb, former co-founder of Ripple. </p>
                        <p>Highlights of the Stellar Network</p>
                        <ul>
                        <li>Speed - Average transaction time is around 4 Seconds</li>
                        <li>Cost - Average transaction cost is 0.0001 XLM or $0.00001</li>
                        <li>No Proof Of Work (POW) better for the planet</li>
                        <li>Partnerships with leading financial businesses</li></ul>
                        <p>The Stellar Development Foundation has been laying the groundwork over the last several years that will lead towards the upcoming digital future of money</p>
                           <h2>USD Coin - USDC</h2>
                           <p>USD Coin - USDC is a digital stablecoin pegged to the US dollar. Launched in 2018 by consortium Circle, it has seen significant domination of the stablecoin markets and as of 2022, there are 42 billion in circulation</p>
                           <p>Highlights of USD Coin</p>
                        <ul>
                        <li>Cash in/out via MoneGram and exchanges</li>
                        <li>Available to transact 24/7</li>
                        <li>Great low cost alternative for remittances</li>
                        <li>Redeemable 1:1 for US dollar</li></ul>
                        <h2>Summary</h2>
                        <p>The Stellar network and USD Coin are a great combination for payment services enabling efficient low cost transactions globally. Add a Stellar based wallet (Lobstr, Freighter etc) and you have an instant personal banking solution available 24/7</p>

                        </div>
                     </div>
                  </div>

                  {
                  // Social Share icon 
                  <Shareicon />
                  }  
                  {
                  // Related Post
                  <Relatedpost />
                  }  
             

               </div>
            </div>
         </div>
      </section>
      </div>
    );
  }
}

export default SetupWallet;
